
import PostsTypesService from "@/modules/posts-types/posts-types-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            PostsTypesService.create(payload).then(response => {
                resolve({
                    message: "PostsTypes criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar PostsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            PostsTypesService.update(payload.id,payload).then(response => {
                resolve({
                    message: "PostsTypes atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar PostsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            PostsTypesService.remove(id).then(response => {
                resolve({
                    message: "PostsTypes removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover PostsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            PostsTypesService.delete(id).then(response => {
                resolve({
                    message: "PostsTypes deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar PostsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            PostsTypesService.get(id).then(response => {
                resolve({
                    message: "PostsTypes encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            PostsTypesService.paginate(query).then(response => {
                resolve({
                    message: "PostsTypes encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            PostsTypesService.all().then(response => {
                resolve({
                    message: "PostsTypes encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsTypes",
                    success: false,
                    error
                });
            })
        })
    }
}
