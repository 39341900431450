
import TaxonomiesTypesService from "@/modules/taxonomies-types/taxonomies-types-service";

export default {
    create(payload) {
        payload.id_user = 1;
        return new Promise((resolve, reject) => {
            TaxonomiesTypesService.create(payload).then(response => {
                resolve({
                    message: "TaxonomiesTypes criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar TaxonomiesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            payload.id_user = 1;
            TaxonomiesTypesService.update(payload.id,payload).then(response => {
                resolve({
                    message: "TaxonomiesTypes atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar TaxonomiesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            TaxonomiesTypesService.remove(id).then(response => {
                resolve({
                    message: "TaxonomiesTypes removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover TaxonomiesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            TaxonomiesTypesService.delete(id).then(response => {
                resolve({
                    message: "TaxonomiesTypes deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar TaxonomiesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            TaxonomiesTypesService.get(id).then(response => {
                resolve({
                    message: "TaxonomiesTypes encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar TaxonomiesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            TaxonomiesTypesService.paginate(query).then(response => {
                resolve({
                    message: "TaxonomiesTypes encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar TaxonomiesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            TaxonomiesTypesService.all().then(response => {
                resolve({
                    message: "TaxonomiesTypes encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar TaxonomiesTypes",
                    success: false,
                    error
                });
            })
        })
    }
}
