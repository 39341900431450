
import TaxonomiesService from "@/modules/taxonomies/taxonomies-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            TaxonomiesService.create(payload).then(response => {
                resolve({
                    message: "Taxonomies criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar Taxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            TaxonomiesService.update(payload.id,payload).then(response => {
                resolve({
                    message: "Taxonomies atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar Taxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            TaxonomiesService.remove(id).then(response => {
                resolve({
                    message: "Taxonomies removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover Taxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            TaxonomiesService.delete(id).then(response => {
                resolve({
                    message: "Taxonomies deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar Taxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            TaxonomiesService.get(id).then(response => {
                resolve({
                    message: "Taxonomies encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Taxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            TaxonomiesService.paginate(query).then(response => {
                resolve({
                    message: "Taxonomies encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Taxonomies",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            TaxonomiesService.all().then(response => {
                resolve({
                    message: "Taxonomies encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Taxonomies",
                    success: false,
                    error
                });
            })
        })
    }
}
